import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifLabel, MotifButton, MotifDatePicker, MotifErrorMessage } from '@ey-xd/motif-react';

import { useLoading } from '../utils/loadingcontext';
import { formatDate } from '../utils/utils';
import { MakeRequests } from '../utils/request';

// A debug function to easily change indiviual task dates. If debug features are enabled, the button can be found in the top right of a task pop up.

const DebugTaskDateModal = ({ showDateModal, setShowDateModal, taskStartDate, taskDeadline, taskType, taskId }) => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    const screenReaderAlertRef = useRef(null);
    const request = new MakeRequests();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);

    // Utility function to convert YYYY-MM-DD string to Date object
    const parseDate = (dateString) => {
        if (!dateString) return null;  // Handle null or undefined input
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // Month is 0-based, so subtract 1
    };

    const [startDate, setStartDate] = useState(parseDate(taskStartDate));
    const [deadline, setDeadline] = useState(parseDate(taskDeadline));
    const [isDateChanged, setIsDateChanged] = useState(false);

    // Use useEffect to update the state when taskStartDate or taskDeadline changes
    useEffect(() => {
        const initialStartDate = parseDate(taskStartDate);
        const initialDeadline = parseDate(taskDeadline);

        setStartDate(initialStartDate);
        setDeadline(initialDeadline);

        // Reset the isDateChanged flag when new task dates are loaded
        setIsDateChanged(false);
    }, [taskStartDate, taskDeadline]);

    // Function to check if either date has changed
    const checkDateChange = (newStartDate, newDeadline) => {
        const isStartDateChanged = newStartDate?.getTime() !== parseDate(taskStartDate)?.getTime();
        const isDeadlineChanged = newDeadline?.getTime() !== parseDate(taskDeadline)?.getTime();
        setIsDateChanged(isStartDateChanged || isDeadlineChanged);
    };

    // Update startDate and check for changes
    const handleStartDateChange = (date) => {
        setStartDate(date);
        checkDateChange(date, deadline);
    };

    // Update deadline and check for changes
    const handleDeadlineChange = (date) => {
        setDeadline(date);
        checkDateChange(startDate, date);
    };

    const validateForm = () => {
        if (!startDate || !deadline) {
            const unfilledFields = [];

            if (!startDate) unfilledFields.push(t('valid_startdate', { ns: 'add_control' }));
            if (!deadline) unfilledFields.push(t('valid_deadline', { ns: 'add_control' }));

            screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields.join(', ')}`;
            return false;
        }

        return true;
    };

    const submitForm = async () => {
        setIsFormSubmitted(true);

        if (validateForm()) {

            const startDateFormat = formatDate(startDate);
            const deadlineFormat = formatDate(deadline);

            const endpoint = taskType === 'control'
                ? `ct-change-date/${taskId}/`
                : `mt-change-date/${taskId}/`;

            const body = {
                'start_date': startDateFormat,
                'deadline': deadlineFormat,
            };

            try {
                setIsLoading(true);
                await request.post(endpoint, body, 'PATCH');
                setConfirmModalVisibility(true);
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <MotifModal show={showDateModal} onClose={() => setShowDateModal(false)} style={{ height: '85%' }}>
                <MotifModalHeader>Change Date</MotifModalHeader>
                <MotifModalBody>
                    {startDate && deadline ? (
                        <>
                            <div className="row m-2">
                                <MotifLabel>{t('startdate', { ns: 'general' })}</MotifLabel>
                                <MotifDatePicker
                                    calendarType="iso8601"
                                    value={startDate}
                                    disabled={isLoading}
                                    onChange={handleStartDateChange}
                                    showLeadingZeros={true}
                                    format="dd-MM-yyyy"
                                    yearPlaceholder="YYYY"
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    maxDate={deadline ? deadline : null}
                                    ariaLabelledBy="task-startdate"
                                />
                                {(startDate === null && isFormSubmitted) && <MotifErrorMessage>
                                    {t('valid_startdate', { ns: 'add_control' })}</MotifErrorMessage>}
                            </div>
                            <div className="row m-2">
                                <MotifLabel>{t('deadline', { ns: 'general' })}</MotifLabel>
                                <MotifDatePicker
                                    calendarType="iso8601"
                                    value={deadline}
                                    disabled={isLoading}
                                    onChange={handleDeadlineChange}
                                    showLeadingZeros={true}
                                    format="dd-MM-yyyy"
                                    yearPlaceholder="YYYY"
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    ariaLabelledBy="task-deadline"
                                />
                                {(deadline === null && isFormSubmitted) && <MotifErrorMessage>
                                    {t('valid_deadline', { ns: 'add_control' })}</MotifErrorMessage>}
                            </div>
                        </>
                    ) : null}
                    <p className="p-2">Debug function to change an individual task date.</p>
                </MotifModalBody>
                <MotifModalFooter>
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-6 text-right d-flex justify-content-end">
                            <MotifButton
                                variant="primary"
                                className="me-3"
                                disabled={!isDateChanged || isLoading}
                                onClick={submitForm}>
                                Change Date
                            </MotifButton>
                            <MotifButton
                                variant="primary"
                                className="me-3"
                                disabled={isLoading}
                                onClick={() => setShowDateModal(false)}>
                                {t('close', { ns: 'general' })}
                            </MotifButton>
                        </div>
                    </div>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isConfirmModalVisible} onClose={() => setConfirmModalVisibility(false)}>
                <MotifModalHeader closeModalButton={true}>{t('alert', { ns: 'general' })}</MotifModalHeader>
                <MotifModalBody>Date changed. Changes visible after refreshing the page.</MotifModalBody>
                <MotifModalFooter>
                    <MotifButton
                        variant="primary"
                        className="me-3"
                        disabled={isLoading}
                        onClick={() => { setShowDateModal(false); setConfirmModalVisibility(false); }}>
                        {t('close', { ns: 'general' })}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </>
    );
};

export default DebugTaskDateModal;